@import "styles";

.footer_text {
  font-size: .9em;
  text-align: center;
}

@media only screen and (max-width: 992px) {
  .nav-img {
    display: none;
  }
  .navbar-brand {
    font-size: .9em;
  }
}

@media only screen and (max-width: 600px) {
  .col-lg-8 {
    padding-left: 1.5em;
    padding-right: 1.5em;
  }
}

.col-lg-8 {
  img {
    height: 100px;
    display: block;
    margin-left: auto;
    margin-right: auto;
  }
}

* {
  font-family: 'Open Sans';
}

header.masthead {
  background-color: teal!important;
}